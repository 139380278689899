<template>
  <div :class="$style.catalog">
    <div :class="$style.tables">
      <div :class="$style.wrapper">
        <div :class="$style.filterWrapper">
          <div :class="$style.header">
            <span :class="$style.categoryName">Главный Каталог</span>
            <el-button
              size="small"
              type="primary"
              :disabled="!catalog.main.selectedProducts.length"
              @click="addProducts"
            >
              Добавить
            </el-button>
          </div>
          <div :class="$style.filters">
            <div :class="$style.search">
              <Autocomplete
                :search="catalog.main.query.search"
                placeholder="Поиск"
                :valueNames="['name']"
                @querySearch="querySearch($event, 'main')"
                @selectItem="handleSelectProduct"
                @handleFindItems="getCatalog('main')"
                @input="catalog.main.query.search = $event"
              />
              <el-button
                :class="$style.searchButton"
                type="primary"
                @click="getCatalog('main')"
              >
                Показать
              </el-button>
            </div>
            <el-button
              type="primary"
              @click="isFiltersCatalogOpen.main = !isFiltersCatalogOpen.main"
              >Фильтры</el-button
            >
            <Dropdown
              :isOpenDropDown="isFiltersCatalogOpen.main"
              title="Фильтры главного каталога"
              :class="$style.dropdown"
              @submitFilters="submitFilters('main')"
              @resetFilters="resetFilters('main')"
            >
              <div :class="$style.filter">
                <p>Активный:</p>
                <el-checkbox v-model="catalog.main.filters.isActive" />
              </div>
              <div :class="$style.filter">
                <p>Бренды:</p>
                <el-select
                  v-model="catalog.main.filters.brandsIds"
                  multiple
                  placeholder="Выбрать"
                >
                  <el-option
                    v-for="brand of brands"
                    :key="brand.id"
                    :label="brand.name"
                    :value="brand.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div :class="$style.filter">
                <p>Категории:</p>
                <el-select
                  v-model="catalog.main.filters.categoriesIds"
                  multiple
                  placeholder="Выбрать"
                >
                  <el-option
                    v-for="category of categories"
                    :key="category.id"
                    :label="category.name"
                    :value="category.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </Dropdown>
          </div>
        </div>
        <el-table
          ref="catalogTable"
          :data="catalog.main.products"
          @selection-change="handleSelectionChange($event, 'main')"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            property="vendorCode"
            label="Артикул"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            property="name"
            label="Название"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="Изображение" width="120"
            ><template slot-scope="scope">
              <Gallery
                :images="setGalleryImages(scope.row?.images)"
                :image="setupImagesListData(scope.row?.images)?.original"
              /> </template
          ></el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <ActionButtons
                :add-trash="false"
                :view-link="
                  getRoute({
                    route: $options.ADDEVENT_ROUTES.CATALOG.PRODUCT,
                    params: { id: scope.row?.slug },
                    site: $options.PROJECTS.ADDEVENTS,
                  })
                "
              />
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :class="$style.pagination"
          layout="prev, pager, next"
          :page-size="catalog.main.query.limit"
          :total="catalog.main.total"
          :current-page.sync="catalog.main.query.page"
          @current-change="getCatalog('main')"
          background
        >
        </el-pagination>
      </div>
      <div :class="$style.wrapper">
        <div :class="$style.filterWrapper">
          <div :class="$style.header">
            <span :class="$style.categoryName">{{ category.name }}</span>
            <el-button
              size="small"
              type="primary"
              :disabled="!catalog.category.selectedProducts.length"
              @click="removeProducts"
            >
              Удалить
            </el-button>
          </div>
          <div :class="$style.filters">
            <div :class="$style.search">
              <Autocomplete
                :search="catalog.category.query.search"
                placeholder="Поиск"
                :valueNames="['name']"
                @querySearch="querySearch($event, 'category')"
                @selectItem="handleSelectProduct"
                @handleFindItems="getCatalog('category')"
                @input="catalog.category.query.search = $event"
              />
              <el-button
                :class="$style.searchButton"
                type="primary"
                @click="getCatalog('category')"
              >
                Показать
              </el-button>
            </div>
            <el-button
              type="primary"
              @click="
                isFiltersCatalogOpen.category = !isCatalogFiltersOpen.category
              "
              >Фильтры</el-button
            >
            <Dropdown
              :isOpenDropDown="isFiltersCatalogOpen.category"
              title="Фильтры каталога категории"
              :class="$style.dropdown"
              @submitFilters="submitFilters('category')"
              @resetFilters="resetFilters('category')"
            >
              <div :class="$style.filter">
                <p>Активный:</p>
                <el-checkbox v-model="catalog.category.filters.isActive" />
              </div>
              <div :class="$style.filter">
                <p>Бренды:</p>
                <el-select
                  v-model="catalog.category.filters.brandsIds"
                  multiple
                  placeholder="Выбрать"
                >
                  <el-option
                    v-for="brand of brands"
                    :key="brand.id"
                    :label="brand.name"
                    :value="brand.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div :class="$style.filter">
                <p>Категории:</p>
                <el-select
                  v-model="catalog.category.filters.categoriesIds"
                  multiple
                  placeholder="Выбрать"
                >
                  <el-option
                    v-for="category of categories"
                    :key="category.id"
                    :label="category.name"
                    :value="category.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </Dropdown>
          </div>
        </div>

        <el-table
          ref="catalogTable"
          :data="catalog.category.products"
          @selection-change="handleSelectionChange($event, 'category')"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            property="vendorCode"
            label="Артикул"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            property="name"
            label="Название"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="Изображение" width="120"
            ><template slot-scope="scope">
              <Gallery
                :images="setGalleryImages(scope.row?.images)"
                :image="setupImagesListData(scope.row?.images)?.original"
              /> </template
          ></el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <ActionButtons
                :add-trash="false"
                :view-link="
                  getRoute({
                    route: $options.ADDEVENT_ROUTES.CATALOG.PRODUCT,
                    params: { id: scope.row?.slug },
                    site: $options.PROJECTS.ADDEVENTS,
                  })
                "
              />
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :class="$style.pagination"
          layout="prev, pager, next"
          :page-size="catalog.category.query.limit"
          :total="catalog.category.total"
          :current-page.sync="catalog.category.query.page"
          @current-change="getCatalog('category')"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRoute,
  ADDEVENT_ROUTES,
  ADDEVENT_ADMIN_ROUTES,
} from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'
import { SORTING_TYPES } from '@/constants/common'

import delivery from '@/delivery'
import images from '@/mixins/images'
import notifications from '@/mixins/notifications'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import Gallery from '@/components/atoms/Gallery.vue'
import Dropdown from '@/components/atoms/Dropdown'

export default {
  components: { Autocomplete, Gallery, ActionButtons, Dropdown },
  ADDEVENT_ROUTES,
  PROJECTS,
  mixins: [images, notifications],
  data() {
    return {
      isCatalogFiltersOpen: false,
      isCategoryCatalogFiltersOpen: false,
      brands: [],
      categories: [],
      category: {},
      catalog: {
        main: {
          products: [],
          selectedProducts: [],
          total: 0,
          query: {
            page: 1,
            limit: 20,
            search: '',
          },
          filters: {
            isActive: false,
            brandsIds: [],
            categoriesIds: [],
          },
        },
        category: {
          products: [],
          selectedProducts: [],
          total: 0,
          query: {
            page: 1,
            limit: 20,
            search: '',
          },
          filters: {
            isActive: false,
            brandsIds: [],
            categoriesIds: [],
          },
        },
      },
      isFiltersCatalogOpen: {
        category: false,
        main: false,
      },
    }
  },
  async created() {
    await Promise.all([
      this.getCategory(),
      this.refreshAllCatalogs(),
      this.getBrands(),
      this.getCategories(),
    ])
  },
  methods: {
    async getCategories() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.CategoriesActions.getList({
          page: 1,
          limit: 100,
          orderBy: 'name',
          orderDir: SORTING_TYPES.DESC,
        })

      loading.close()

      if (error) return

      this.categories = value?.data ?? []
    },
    async getBrands() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.BrandsActions.getList({
          page: 1,
          limit: 100,
          orderBy: 'name',
          orderDir: SORTING_TYPES.DESC,
        })

      loading.close()

      if (error) return

      this.brands = value?.data ?? []
    },
    async getCategory() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.CategoriesActions.getOne(
          this.$route.params.id,
        )

      loading.close()

      if (error) {
        return
      }
      this.category = value
    },
    async addProducts() {
      let isConfirmAddAll
      const isAllSelected =
        this.catalog.main?.products?.length ===
        this.catalog.main?.selectedProducts?.length
      if (isAllSelected) {
        isConfirmAddAll = confirm(
          'Добавить все продукты, соответствующие фильтрам?',
        )
      }
      if (!isAllSelected || isConfirmAddAll) {
        const data = {
          ...this.category,
          image: {
            ...this.category.image,
            origin: this.category.image?.original,
          },
          productIds: [
            ...this.catalog.main?.selectedProducts?.map((item) => item.id),
            ...this.catalog.category?.products?.map((e) => e.id),
          ],
          setDefault: this.category?.seo?.type == 'TEMPLATE' ? true : false,
          seo: {
            ...this.category?.seo,
            ogImage: {
              ...this.category?.seo?.ogImage,
              origin: this.category?.seo?.ogImage?.original,
            },
          },
        }
        const loading = this.$loading({
          lock: true,
        })

        const { error } =
          await delivery.AddeventServiceCore.CategoriesActions.update(
            this.$route.params.id,
            data,
          )

        loading.close()
        if (error) {
          this.showNotification('Ошибка обновления товаров категории', 'error')
          return
        }

        this.showNotification('Товары категории успешно изменены', 'success')
        await this.refreshAllCatalogs()
      }
      return
    },
    async removeProducts() {
      let isConfirmRemoveAll
      const isAllSelected =
        this.catalog.category.products.length ===
        this.catalog.category.selectedProducts.length
      if (isAllSelected) {
        isConfirmRemoveAll = confirm(
          'Удалить все продукты, соответствующие фильтрам?',
        )
      }
      if (!isAllSelected || isConfirmRemoveAll) {
        const loading = this.$loading({
          lock: true,
        })

        const { error } =
          await delivery.AddeventServiceCore.ProductCategoriesActions.deleteProductsCategory(
            {
              productIds:
                this.catalog.category.selectedProducts?.map((e) => e.id) ?? [],
              categoryId: this.$route.params.id,
            },
          )

        loading.close()
        if (error) {
          this.showNotification('Ошибка удаления товаров категории', 'error')
          return
        }

        this.showNotification('Товары категории успешно изменены', 'success')
        await this.refreshAllCatalogs()
      }
      return
    },
    handleSelectionChange(val, name) {
      this.catalog[name].selectedProducts = val
    },
    async getCatalog(catalogName) {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        [catalogName === 'category' ? 'categoryId' : 'excludedCategory']:
          this.$route.params.id,
        search: this.catalog[catalogName].query.search,
        limit: this.catalog[catalogName].query.limit,
        page: this.catalog[catalogName].query.page,
      }

      if (this.catalog[catalogName].filters?.isActive) {
        query.isActive = this.catalog[catalogName].filters.isActive
      }

      if (this.catalog[catalogName].filters?.brandsIds?.length) {
        query.brandsIds = this.catalog[catalogName].filters.brandsIds
      }

      if (this.catalog[catalogName].filters?.categoriesIds?.length) {
        query.categoriesIds = this.catalog[catalogName].filters.categoriesIds
      }

      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getList(query)

      loading.close()

      if (error) return

      this.catalog[catalogName].products = value?.data ?? []
      this.catalog[catalogName].total = value.meta.count
    },
    async refreshAllCatalogs() {
      await Promise.all([this.getCatalog('main'), this.getCatalog('category')])
    },
    handleSelectProduct(selectedProduct) {
      this.$router.push(
        this.getRoute({
          route: ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.UPDATE,
          params: { id: selectedProduct?.id },
        }),
      )
    },

    async querySearch({ setSearchItems, queryString }, catalogName) {
      const query = {
        limit: 10,
        search: queryString,
        [catalogName === 'category' ? 'categoryId' : 'excludedCategory']:
          this.$route.params.id,
      }

      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getList(query)

      if (error) return

      setSearchItems(value.data)
    },
    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
    async submitFilters(catalogName) {
      this.catalog[catalogName].query.page = 1

      this.isFiltersCatalogOpen[catalogName] = false

      await this.getCatalog(catalogName)
    },
    async resetFilters(catalogName) {
      this.catalog[catalogName].query.page = 1

      this.catalog[catalogName].filters = {}

      this.isFiltersCatalogOpen[catalogName] = false

      await this.getCatalog(catalogName)
    },
  },
}
</script>

<style lang="scss" module>
.catalog {
  padding: 0 1rem;

  .tables {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    .wrapper {
      margin-right: 1px;
      height: 92vh;
      overflow-y: auto;

      .filterWrapper {
        display: flex;
        flex-direction: column;
        @include stickyWrapperCustom('flex-end', 5, 0);
        .filters {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 1rem;

          .search {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            input {
              border-radius: 0;
            }

            .searchButton {
              margin: 0;
              border-radius: 0;
            }
          }
          .filtersButtonWrapper {
            position: relative;

            .filtersButton {
              margin-right: 0;
            }
          }

          .dropdown {
            .filter {
              margin-top: 0.5rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              p {
                margin-right: 1rem;
              }
            }
          }
        }

        .header {
          display: flex;
          width: 100%;
          padding-top: 0.5rem;
          background-color: #f7f9fb;
          align-items: center;
          .categoryName {
            @include H200;
            margin: 0 auto 1rem 0.5rem;
          }
        }
      }
      .pagination {
        @include pagination;
      }
    }
  }
}
</style>
